import { AxiosClient } from './config';

export async function createSession(token) {
  try {
    var res = await AxiosClient(token).post('/session', {})
    return res
  } catch (e) {
    console.log(e)
  }
}

export async function deleteSession() {
  try {
    var res = await AxiosClient().delete('/session')
    return res
  } catch (e) {
    console.log(e)
  }
}
