import axios from "axios"

// Axios vs. fetch
// https://blog.logrocket.com/axios-vs-fetch-best-http-requests/
console.log("isProd", process.env.isProd);

let URL = "http://localhost:8080"
if (process.env.isProd) {
  URL = "https://grokbasis.com"
}

const Prefix = "api/v1";
const BaseURL = `${URL}/${Prefix}`;

const ac = axios.create({
  baseURL: BaseURL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

export function AxiosClient(token) {
  if (token) {
    return axios.create({
      baseURL: BaseURL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
  }

  return ac
}