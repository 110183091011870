<script>
  import {createSession} from "./api/session";
  import { onMount } from 'svelte';
  import Router from 'svelte-spa-router';
  import { wrap } from 'svelte-spa-router/wrap';

  import AuthStore from './stores/AuthStore';
  import { getAnalytics } from 'firebase/analytics';
  import { initializeApp } from 'firebase/app';
  import {
    getAuth,
    onAuthStateChanged,
  } from 'firebase/auth';

  import Header from './components/Header.svelte';
  import Footer from './components/Footer.svelte';

  import Home from './routes/Home.svelte';
  import NotFound from './routes/NotFound.svelte';

  import Signup from './routes/Signup.svelte';

  const routes = {
    // Exact path
    '/': Home,

    '/signup': wrap({
      asyncComponent: () => Signup,
    }),

    // Catch-all
    // This is optional, but if present it must be the last
    '*': NotFound,
  };

  onMount(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyATw34I521q2eYq1InscTvw1QDyzfJ311c',
      authDomain: 'graphly.firebaseapp.com',
      projectId: 'graphly',
      storageBucket: 'graphly.appspot.com',
      messagingSenderId: '955940846888',
      appId: '1:955940846888:web:4d213e7bb89cbcb5cd641d',
      measurementId: 'G-P36L633HPC',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
      if (auth) {
        if (user) {
          try {
            const token = await user.getIdTokenResult();
            var res = await createSession(token.token)
            console.log(res.status)
          } catch (e) {
            console.log(e)
          }
        }
        
        AuthStore.set({
          isLoggedIn: user !== null,
          profile: (user) ? {email: user.email, photoURL: user.photoURL} : null,
          firebaseControlled: true
        })
      }
    });
  });
</script>

<div class="flex flex-col h-screen justify-between">
  <div>
    <Header />
  </div>
  <div>
    <Router {routes} restoreScrollState={true} />
  </div>
  <div>
    <Footer />
  </div>
</div>


<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>


