<script>
  import { onMount } from 'svelte';
  import { scale } from 'svelte/transition';
  import { deleteSession } from '../api/session';
  import AuthStore from '../stores/AuthStore';
  import { getAuth } from 'firebase/auth';
  import Avatar from '../components/Avatar.svelte';
  import { replace } from 'svelte-spa-router';

  let show = false;
  let menu = null;

  onMount(() => {
    const handleOutsideClick = (event) => {
      if (show && !menu.contains(event.target)) {
        show = false;
      }
    };

    const handleEscape = (event) => {
      if (show && event.key === 'Escape') {
        show = false;
      }
    };

    document.addEventListener('click', handleOutsideClick, false);
    document.addEventListener('keyup', handleEscape, false);

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
      document.removeEventListener('keyup', handleEscape, false);
    };
  });

  async function logout() {
    try {
      const auth = getAuth();
      await auth.signOut();

      const res = await deleteSession();
      console.log(res.status);
    } catch (e) {
      console.log(e);
    }

    await replace('/signup');
  }
</script>

<header class="text-gray-600 body-font">
  <div
    class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center"
  >
    <a
      class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
      href="/"
    >
      <svg
        viewBox="0 0 24 24"
        class="w-10 h-10 text-white p-1 bg-green-500 rounded-full"
      >
        <path
          fill="currentColor"
          d="M10,12A2,2 0 0,0 8,14A2,2 0 0,0 10,16A2,2 0 0,0 12,14A2,2 0 0,0 10,12M6,8A2,2 0 0,0 4,10A2,2 0 0,0 6,12A2,2 0 0,0 8,10A2,2 0 0,0 6,8M6,16A2,2 0 0,0 4,18A2,2 0 0,0 6,20A2,2 0 0,0 8,18A2,2 0 0,0 6,16M18,8A2,2 0 0,0 20,6A2,2 0 0,0 18,4A2,2 0 0,0 16,6A2,2 0 0,0 18,8M14,16A2,2 0 0,0 12,18A2,2 0 0,0 14,20A2,2 0 0,0 16,18A2,2 0 0,0 14,16M18,12A2,2 0 0,0 16,14A2,2 0 0,0 18,16A2,2 0 0,0 20,14A2,2 0 0,0 18,12M14,8A2,2 0 0,0 12,10A2,2 0 0,0 14,12A2,2 0 0,0 16,10A2,2 0 0,0 14,8M10,4A2,2 0 0,0 8,6A2,2 0 0,0 10,8A2,2 0 0,0 12,6A2,2 0 0,0 10,4Z"
        />
      </svg>
      <span class="ml-3 text-xl">grokbasis</span>
    </a>
    <nav
      class="md:ml-auto flex flex-wrap items-center text-base justify-center"
    >
      <!-- <a class="mr-5 hover:text-gray-900" href="/">Home</a>
      <a class="mr-5 hover:text-gray-900" href="/#/pricing">Pricing</a>
      <a class="mr-5 hover:text-gray-900" href="/#/designer">Designer</a>
      <a class="mr-5 hover:text-gray-900" href="/#/perspective">Perspective</a>
      <a class="mr-5 hover:text-gray-900" href="/#/about">About Us</a> -->
      {#if $AuthStore.isLoggedIn}
        <div
          bind:this={menu}
          class="flex relative w-8 h-8 bg-orange-500 justify-center items-center m-1 mr-2 text-xl rounded-full text-white"
        >
          <button on:click={() => (show = !show)} class="menu">
            <img
              class="rounded-full"
              src={$AuthStore.profile.photoURL}
              referrerpolicy="no-referrer"
              alt="Avatar"
            />
            <div
              class="bg-green-500 rounded-full w-2 h-2 absolute bottom-0 left-0"
            />
          </button>
        </div>
      {/if}
    </nav>
  </div>
  {#if show}
    <div
      in:scale={{ duration: 100, start: 0.95 }}
      out:scale={{ duration: 75, start: 0.95 }}
      class="origin-right absolute right-20 w-40 py-2 bg-gray-200 rounded shadow-lg text-sm"
    >
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a href="#" class="block px-2 hover:bg-green-600 hover:text-white"
        >Profile</a
      >
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a
        href="#"
        class="block px-2 hover:bg-green-600 hover:text-white"
        on:click|preventDefault={logout}>Logout</a
      >
    </div>
  {/if}
</header>
