import { writable } from "svelte/store";

const AuthStore = writable ({
  isLoggedIn: false,
  profile: null,
  firebaseControlled: false,
})

export default {
  subscribe: AuthStore.subscribe,
  set: AuthStore.set,
}
