<script>

</script>

<section class="text-gray-600 body-font">
  <div class="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
    <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Coming Soon</h1>
      <p class="mb-8 leading-relaxed">We're hard at work building something cool.</p>
    </div>
  </div>
</section>